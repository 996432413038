import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HelperStateType, MessageType } from './types';

const INITIAL_STATE: HelperStateType = {
  isLoading: false,
  message: null
};

export const helperSlice = createSlice({
  name: 'helper',
  initialState: INITIAL_STATE,
  reducers: {
    setIsLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setMessageAction: (state, action: PayloadAction<MessageType>) => {
      state.message = action.payload;
    }
  }
});

export default helperSlice.reducer;
