import { Helmet } from 'react-helmet';

const GTM_ID: string | undefined = process.env.REACT_APP_GTM_ID;
const GTM_URL: string | undefined = process.env.REACT_APP_GTM_URL;

function GoogleTagManager() {
  return (
    <Helmet>
      <script>
        {`
          (function(w,d,s,l,i) {
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start': new Date().getTime(),
               event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s),dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src= '${GTM_URL}/gtm.js?id=' + i + dl; 
            f.parentNode.insertBefore(j,f);
          })(window, document, 'script', 'dataLayer', '${GTM_ID}');
        `}
      </script>
    </Helmet>
  );
}

export default GoogleTagManager;
