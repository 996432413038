import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CandidateExamResultType, ExamInfoType, ExamType } from 'types/exam';
import { formattedExamTitle } from 'utils/formatters';
import { ExamStateType } from './types';

const INITIAL_STATE: ExamStateType = {
  title: null,
  id: null,
  timeQuestions: 0,
  questions: [],
  questionsNumber: 0,
  attempts: 0,
  result: null,
  level: 0,
};

export const examSlice = createSlice({
  name: 'exam',
  initialState: INITIAL_STATE,
  reducers: {
    setExamInfoAction: (state, action: PayloadAction<ExamInfoType>) => {
      const { exam, user } = action.payload;

      if (exam) {
        const { id, title, time, countQuestions, level } = exam;

        state.title = formattedExamTitle(title, level);
        state.id = id;
        state.timeQuestions = time / 1000; // CONVERTING TO SECONDS
        state.questionsNumber = countQuestions;
      }

      if (user) {
        const { curriculum } = user;
        const attempts = curriculum?.curriculumExams?.attempts || 0;

        state.attempts = attempts + 1;
      }
    },
    setExamAction: (state, action: PayloadAction<ExamType>) => {
      const { questions } = action.payload;

      const newQuestions = questions.map((question) => {
        question.time /= 1000; // CONVERTING TO SECONDS
        return question;
      });

      state.questions = newQuestions;
    },
    setExamResult: (state, action: PayloadAction<CandidateExamResultType>) => {
      state.result = action.payload;
    }
  }
});

export default examSlice.reducer;
