import { AxiosError, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { call, put, takeEvery } from 'redux-saga/effects';

import { AuthService, LoginResponseRequestType } from 'services/auth.service';
import { CandidateService } from 'services/candidate.service';
import { UserType } from 'types/user';
import { setCookieSessionToken } from 'utils/session';
import { getUserType } from 'utils/user';
import { getUserAction, setUserAction, setIsLoadingAuthAction } from './actions';
import {
  AuthSagaActionsType,
  GetUserActionPayloadType,
  LoginActionPayloadType
} from './types';

import { setIsLoadingAction, setMessageAction } from '../helper/actions';

function* loginSaga(action: LoginActionPayloadType) {
  const { email, password, isCompanyLogin, callback } = action.payload;

  yield put(setIsLoadingAction(true));
  try {
    const response: AxiosResponse<LoginResponseRequestType> = yield call(
      AuthService.login,
      email,
      password,
      isCompanyLogin
    );
    const { data } = response;

    yield call(setCookieSessionToken, data.token);

    yield put(getUserAction(data.token, callback));

    yield put(setMessageAction(null));
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(setMessageAction({ type: 'error', content: error.message }));
    }
    yield put(setIsLoadingAction(false));
  }
}

function* getUserSaga(action: GetUserActionPayloadType) {
  const { token, callback } = action.payload;

  yield put(setIsLoadingAction(true));
  try {
    const { associatedCompanyRoles } =
      jwtDecode<LoginResponseRequestType>(token);
    const userType = getUserType(associatedCompanyRoles);

    if (userType === 'candidate') {
      const { data }: AxiosResponse<UserType> = yield call(
        CandidateService.getGeneral
      );

      yield put(setUserAction({ token, userType, user: data }));

      yield put(setMessageAction(null));

      if (callback) yield call(callback);
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(setMessageAction({ type: 'error', content: error.message }));
    }
  }
  yield put(setIsLoadingAuthAction(false));
  yield put(setIsLoadingAction(false));
}

const authWatcher = [
  takeEvery(AuthSagaActionsType.LOGIN, loginSaga),
  takeEvery(AuthSagaActionsType.GET_USER, getUserSaga)
];
export default authWatcher;
