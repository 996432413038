import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import api from 'services/api';
import authApi from 'services/authApi';
import { getUserAction } from 'store/modules/auth/actions';
import { setIsLoadingAction } from 'store/modules/helper/actions';
import { getCookieSessionToken } from 'utils/session';

interface AppProviderProps {
  children: JSX.Element;
}

function AppProvider({ children }: AppProviderProps) {
  const dispatch = useAppDispatch();
  const { isAuthenticated, isLoadingAuth } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      const sessionToken = getCookieSessionToken();

      if (sessionToken) {
        api.defaults.headers.common.Authorization = `Bearer ${sessionToken}`;
        authApi.defaults.headers.common.Authorization = `Bearer ${sessionToken}`;
        dispatch(getUserAction(sessionToken));
      } else {
        dispatch(setIsLoadingAction(false));
        setIsLoading(false);
      }
    }
  }, [isAuthenticated]);

  if (isLoadingAuth && isLoading) {
    return <div />;
  }

  return children;
}

export default AppProvider;
