import { PayloadAction } from '@reduxjs/toolkit';
import {
  CandidateExamResultType,
  CandidateExamAnswersType,
  QuestionType
} from 'types/exam';

export interface ExamStateType {
  id: string | null;
  title: string | null;
  timeQuestions: number;
  questions: QuestionType[];
  questionsNumber: number;
  attempts: number;
  result: CandidateExamResultType | null;
  level: number;
}

export enum ExamSagaActionsType {
  GET_EXAM_INFO = 'GET_EXAM_INFO',
  GET_EXAM = 'GET_EXAM',
  SEND_EXAM_ANSWERS = 'SEND_EXAM_ANSWERS'
}

export type GetExamActionInfoPayloadType = PayloadAction<{
  examId: string;
  callback?: () => void;
}>;

export type GetExamActionInfoType = (
  examId: string,
  callback?: () => void
) => GetExamActionInfoPayloadType;

export type GetExamActionPayloadType = PayloadAction<{
  examId: string;
  callback?: () => void;
}>;

export type GetExamActionType = (
  examId: string,
  callback?: () => void
) => GetExamActionPayloadType;

export type SendExamAnswersActionPayloadType = PayloadAction<{
  answers: CandidateExamAnswersType;
  callback?: () => void;
}>;

export type SendExamAnswersActionType = (
  answers: CandidateExamAnswersType,
  callback?: () => void
) => SendExamAnswersActionPayloadType;
